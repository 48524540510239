import React, { useEffect, useRef, useState } from "react"
import braintree from 'braintree-web-drop-in'

import Helmet from "react-helmet"
import Loadable from 'react-loadable'
import Layout from '../components/layout'
import Section, { ContainerSection, TextSection } from '../components/Section'
// import WorldMap from "../components/WorldMap"
import loadable from '@loadable/component'
import MissionaryImage from '../assets/images/general/2.jpg'

const WorldMap = loadable( () => import('../components/WorldMap') )

export default () => {
   
    const siteTitle = "Blackheath and Charlton Baptist Church - Mission"
    
    

    return (
        <div id="missions-page">
            <Helmet title={siteTitle} />

            <TextSection
                background={[MissionaryImage]}
                id="mission" 
                title="BCBC Round The World"
                style="style1"
                description={`Evangelism and Mission is an essential part of our life here at BCBC. 
                
                We believe in supporting those who have answered the call to go out into the world and make disciples for the Kingdom of God.

                By supporting various missions around the world with the help of our partners and also equipping members of our congregation to answer the call to Mission themselves, we hope to contribute in a small way to answering Christ's command in Matthew 28.`}
                alt
                opacity={0.2}
                tint={0.5}
            />


            <ContainerSection 
                data-aos="zoom-in" 
                id="mission-map" 
                style="style1"
                background={[MissionaryImage]} 
                opacity={0.2}
                tint={0.5}
            >
            
{/* 
                <header className="major">
                    <h2>Mission Map</h2>
                </header> */}

                <WorldMap />
            </ContainerSection>
        </div>
    )
}
